var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-header-page"
  }, [_c('div', {
    staticClass: "user-header-box"
  }, [_c('div', {
    staticClass: "user-header__smart-forex"
  }, [_c('div', {
    staticClass: "hamburger-menu"
  }, [_c('div', {
    staticClass: "hamburger-lines"
  })])]), _c('div', {
    staticClass: "user-header__icons"
  }, [_c('div', {
    staticClass: "notification"
  }, [_c('img', {
    attrs: {
      "src": require("./img/fx_bell.svg"),
      "alt": "bell"
    }
  })]), _c('div', {
    staticClass: "person"
  }, [_c('img', {
    attrs: {
      "src": require("./img/fx_user.svg"),
      "alt": "person"
    }
  })])])])]);
}]

export { render, staticRenderFns }
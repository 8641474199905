<template>
  <div class="header">
    <div class="header__row">
      <div class="header__column header__column--left"></div>

      <div class="header__column header__column--center">
        <a href="/" class="image-link-logo">
          <img src="/img/logo.svg" alt="VEQBER" />
        </a>
      </div>

      <div class="header__column header__column--right">
        <div class="actions-links">
          <a
            class="join-us-button"
            target="_blank"
            href="https://veqber.com/auth/signup"
          >
            Join us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  background: #282842;
  padding: 8px 40px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 767px) {
    padding: 8px 16px;
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: 767px) {
      justify-content: space-between;
    }
  }

  &__column {
    width: calc(100% / 3);

    &--left {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &--center {
      display: flex;
      justify-content: center;
    }

    &--right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .image-link-logo {
    img {
      width: 103px;
    }
  }

  .home-link {
    margin-right: 20px;
    color: #e9bd42;
    text-decoration: none;
    font-size: 17px;
    padding: 10px 15px;
    font-weight: bold;

    &:hover {
      color: #ffcf49;
    }
  }

  .join-us-button {
    border-radius: 6px;
    padding: 14px 24px 14px 24px;
    background-color: #eae3dd;
    font-size: 16px;
    font-weight: 900;
    text-decoration: none;
    text-align: left;
    color: #000;
    white-space: nowrap;
    transition: all ease 0.4s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>

<template>
  <div class="user-header-page">
    <div class="user-header-box">
      <div class="user-header__smart-forex">
        <div class="hamburger-menu">
          <div class="hamburger-lines">
            <!-- <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span> -->
            <!-- <img src="./img/logo-eliux.png" alt="logo"> -->
          </div>
        </div>
        <!-- <div class="user-details">
          <div class="user-logo">
            <img src="./img/ava.svg" alt="ava">
          </div>
          <div class="user-info">
            <div class="welcome">
              <span>Welcome Back,</span>
              <span class="user-name"> Wade Warren</span>
            </div>
            <div class="premium-info">
              <span class="monthly">Monthly</span>
              <span class="premium">premium user</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="user-header__icons">
        <div class="notification">
          <img src="./img/fx_bell.svg" alt="bell">
        </div>
        <div class="person">
          <img src="./img/fx_user.svg" alt="person">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('App', ['showUserSidebar']),
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_SIDEBAR']),

    toggle() {
      this.TOGGLE_USER_SIDEBAR(!this.showUserSidebar)
    },
  }
}
</script>
<template>
  <div class="user-layout">
    <notifications />

    <div class="body">
      <AppHeader />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import userHeader from './UserHeader.vue'
import AppHeader from "./AppHeader";

export default {
  components: {
    userHeader,
    AppHeader,
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__row"
  }, [_c('div', {
    staticClass: "header__column header__column--left"
  }), _c('div', {
    staticClass: "header__column header__column--center"
  }, [_c('a', {
    staticClass: "image-link-logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/logo.svg",
      "alt": "VEQBER"
    }
  })])]), _c('div', {
    staticClass: "header__column header__column--right"
  }, [_c('div', {
    staticClass: "actions-links"
  }, [_c('a', {
    staticClass: "join-us-button",
    attrs: {
      "target": "_blank",
      "href": "https://veqber.com/auth/signup"
    }
  }, [_vm._v(" Join us ")])])])])]);
}]

export { render, staticRenderFns }